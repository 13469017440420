











































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component'
import { PagedResults, QuizCurriculumNodeInfo, SortOrderList, TableState, CurriculumNodeResourceSortingOption } from '@/edshed-common/api/types'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import { Api, HttpResponseError } from '@/edshed-common/api'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'
import config from '@/config'

@Component({})
export default class CurriculumNodesView extends mixins(ModalMixin, ComponentHelperBase) {
  private loading: boolean = false
  private QuizShedUrl = config.serverInfo.quiz

  private heading = 'Curriculum Nodes'

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'sort_order',
    dir: 'asc',
    term: ''
  }

  private results: PagedResults<QuizCurriculumNodeInfo> = {
    items: [],
    total: 0
  }

  private recordToEdit: Partial<QuizCurriculumNodeInfo> | null = null

  private recordToChangeSortOrder: Partial<QuizCurriculumNodeInfo> | null = null
  private updatedSortOrder: number = 0
  private maxSortOrder: number = 0
  private draggingRow: Partial<QuizCurriculumNodeInfo> | null = null
  private draggingRowIndex = 0

  private recordToDuplicate: QuizCurriculumNodeInfo | null = null
  private curriculumToDuplicateTo: number = 0
  private parentNodeToDuplicateTo: number | null = null
  private recordResourcesCustomOrder: QuizCurriculumNodeInfo | null = null
  private resourcesSortingOption: CurriculumNodeResourceSortingOption | null = null

  private hasAttemptedToSave = false

  private originalTags: Record<string, string | undefined> = {}

  private newTag: Record<string, string| undefined> = {
    key: '',
    value: ''
  }

  public mounted () {
    this.loadData()

    this.$watch(() => this.$route, () => this.loadData())
    this.$watch(() => this.$store.state.user, () => this.loadData())
  }

  private async loadData () {
    try {
      this.loading = true
      const curriculum_id = this.numberParam('curriculum_id', false)
      let parent_id : number | null | undefined = this.numberParam('parent_id', false)

      if (curriculum_id) {
      // Little hack to show only direct descendants
        parent_id = null

        const curriculum = await Api.getCurriculum(curriculum_id)

        this.heading = `${curriculum.name} Children`
      } else if (parent_id) {
        const parent = await Api.getCurriculumNode(parent_id)
        this.heading = this.ellipsis(`${parent.curriculum_name} > ${parent.qualified_name} Children`, 50)
      }

      this.results = await Api.getCurriculumNodes({ curriculum_id, parent_id }, this.tableState)
      // set sort orders
      this.results.items.forEach((item, index) => {
        item.sort_order = index
      })
      this.maxSortOrder = this.results.items.length - 1
    } catch (err: unknown) {
      this.$buefy.toast.open({ position: 'is-bottom', type: 'is-danger', message: 'Could not load data!' })
    } finally {
      this.loading = false
    }
  }

  get isResource () {
    return this.$route.path === '/curriculum_nodes' && 'parent_id' in this.$route.query
  }

  private onPageChange (page: number) {
    this.tableState.page = page

    this.loadData()
  }

  private onSearchChange () {
    this.loadData()
  }

  private addRow () {
    const curriculum_id = this.numberParam('curriculum_id', false)
    const parent_id = this.numberParam('parent_id', false)

    if (curriculum_id) {
      this.recordToEdit = {
        curriculum_id,
        parent_id: null,
        resource_ids: [],
        lesson_ids: [],
        set_ids: [],
        name: '',
        description: '',
        hidden: 0,
        tags: {}
      }
    } else if (parent_id) {
      this.recordToEdit = {
        parent_id,
        resource_ids: [],
        lesson_ids: [],
        set_ids: [],
        name: '',
        description: '',
        hidden: 0,
        tags: {}
      }
    } else {
      this.recordToEdit = {
        resource_ids: [],
        lesson_ids: [],
        set_ids: [],
        name: '',
        description: '',
        hidden: 0,
        tags: {}
      }
    }
  }

  private onEditRow (row: QuizCurriculumNodeInfo) {
    this.originalTags = { ...row.tags }
    this.recordToEdit = row
  }

  private onChangeRowOrder (row: QuizCurriculumNodeInfo) {
    this.updatedSortOrder = row.sort_order || 0
    this.recordToChangeSortOrder = row
  }

  private onChangeRowResourcesOrder (row: QuizCurriculumNodeInfo) {
    this.recordResourcesCustomOrder = row
    console.log(this.recordResourcesCustomOrder)
  }

  private async saveRowResourcesOrder () {
    try {
      this.loading = true
      if (!this.recordResourcesCustomOrder) {
        throw new Error('no node resources to reorder')
      }

      if (this.resourcesSortingOption === null) {
        throw new Error('Sorting option not selected')
      }

      // api call passing the id taken from the raw object and the param chosen
      await Api.applyCurriculumNodeResourcesOrder(this.recordResourcesCustomOrder.id, this.resourcesSortingOption)

      // reset
      this.recordResourcesCustomOrder = null
      this.resourcesSortingOption = null
      this.loadData()
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: err.message,
          type: 'is-danger'
        })
      }
    } finally {
      this.loading = false
    }
  }

  private onDuplicateRow (row: QuizCurriculumNodeInfo) {
    this.recordToDuplicate = row
  }

  private async duplicateRow () {
    try {
      this.loading = true
      if (!this.recordToDuplicate) {
        throw new Error('No node to duplicate')
      }
      if (!this.curriculumToDuplicateTo) {
        throw new Error('No curriculum selected')
      }
      await Api.cloneCurriculumNode(this.recordToDuplicate.id, { curriculumId: this.curriculumToDuplicateTo, parentId: this.parentNodeToDuplicateTo })
      this.recordToDuplicate = null
      this.loadData()
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: err.message,
          type: 'is-danger'
        })
      }
    } finally {
      this.loading = false
    }
  }

  private async onDeleteRow (row: QuizCurriculumNodeInfo) {
    try {
      this.loading = true
      if (await this.deleteConfirm({ name: row.name })) {
        await Api.deleteCurriculumNode(row.id)
        this.loadData()
        this.$buefy.toast.open({ position: 'is-bottom', type: 'is-success', message: 'Node deleted successfully!' })
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        let message = err.message
        if (err.message.includes('ER_ROW_IS_REFERENCED_2')) {
          message = 'Cannot delete a node which has children! Please, delete the children first.'
        }
        this.$buefy.dialog.alert({
          title: 'Error',
          message,
          type: 'is-danger'
        })
      } else {
        this.$buefy.toast.open({ position: 'is-bottom', type: 'is-danger', message: 'Could not delete the node!' })
      }
    } finally {
      this.loading = false
    }
  }

  private isFormValid () {
    return this.recordToEdit && this.recordToEdit.name && this.recordToEdit.name.length > 0
  }

  private onCloseEdit () {
    this.recordToEdit = null
  }

  private onCloseRowOrder () {
    this.recordToChangeSortOrder = null
  }

  private onCloseRowResourcesOrder () {
    this.recordResourcesCustomOrder = null
    this.resourcesSortingOption = null
    this.loadData()
  }

  private async onSaveEdit () {
    this.hasAttemptedToSave = true

    if (!this.isFormValid()) {
      return
    }

    try {
      this.loading = true
      await Api.saveCurriculumNode(this.recordToEdit as QuizCurriculumNodeInfo)
      this.onCloseEdit()
      this.loadData()
      this.$buefy.toast.open({ position: 'is-bottom', type: 'is-success', message: 'Node saved successfully!' })
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: err.message,
          type: 'is-danger'
        })
      }
    } finally {
      this.loading = false
    }
  }

  private async changeSortOrder (droppedOnIndex: number, movedIndex: number, movedRow: Partial<QuizCurriculumNodeInfo>) {
    if (movedIndex === droppedOnIndex) { return }

    // moved item gets sort order of row dropped on
    movedRow.sort_order = droppedOnIndex

    if (movedIndex > droppedOnIndex) {
      // droped on and above get a new sort order of index + 2
      for (let index = droppedOnIndex; index < movedIndex; index++) {
        this.results.items[index].sort_order = index + 1
      }
    } else {
      // next item after moved until before old each decr
      for (let index = movedIndex + 1; index <= droppedOnIndex; index++) {
        this.results.items[index].sort_order = index - 1
      }
    }

    const apiPayload: SortOrderList = {}

    this.results.items.forEach((item) => {
      if (item.id) {
        apiPayload[item.id] = item.sort_order || 0
      }
    })

    if (apiPayload) {
      try {
        this.loading = true
        await Api.applyCurriculumNodeSortOrders(apiPayload)
        this.loadData()
      } catch (err: unknown) {
        if (err instanceof Error) {
          this.$buefy.dialog.alert({
            title: 'Error',
            message: `Item's position could not be saved - ${err.message}`,
            type: 'is-danger'
          })
        }
      } finally {
        this.loading = false
      }
    }
  }

  private onSaveRowOrder () {
    this.updatedSortOrder = Math.min(this.updatedSortOrder, this.maxSortOrder)
    if (this.recordToChangeSortOrder) {
      if (this.recordToChangeSortOrder.sort_order === undefined || this.recordToChangeSortOrder.sort_order === null) { return }
      const movedIndex: number = this.recordToChangeSortOrder.sort_order
      this.changeSortOrder(this.updatedSortOrder, movedIndex, this.recordToChangeSortOrder)
    }
    this.onCloseRowOrder()
  }

  private dragstart (payload) {
    this.draggingRow = payload.row
    this.draggingRowIndex = payload.index
    payload.event.dataTransfer.effectAllowed = 'copy'
  }

  private dragover (payload) {
    payload.event.dataTransfer.dropEffect = 'copy'
    payload.event.target.closest('tr').classList.add('is-selected')
    payload.event.preventDefault()
  }

  private dragleave (payload) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    payload.event.preventDefault()
  }

  private drop (payload) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    const droppedOnIndex = payload.index
    const movedIndex = this.draggingRowIndex

    if (this.draggingRow) {
      this.changeSortOrder(droppedOnIndex, movedIndex, this.draggingRow)
    }
  }

  addTag () {
    if (this.newTag.key && this.newTag.value && this.recordToEdit && this.recordToEdit.tags) {
      this.$set(this.recordToEdit.tags, this.newTag.key, this.newTag.value)
      this.newTag.key = ''
      this.newTag.value = ''
    }
  }

  removeTag (key: string) {
    if (this.recordToEdit && this.recordToEdit.tags) {
      if (this.originalTags[key]) {
        this.$set(this.recordToEdit.tags, key, null)
      } else {
        this.$delete(this.recordToEdit.tags, key)
      }
    }
  }
}
